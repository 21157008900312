import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AddAdvisorDialog = ({open, onClose}) => {
    const [advisorName, setAdvisorName] = React.useState(''); //set initially to Space to prevent error in textfield from appearing right away.
    const [validInput, setValidInput] = React.useState(false);
    const [advisorNameErrMsg, setAdvisorNameErrMsg] = React.useState('');

    React.useEffect(() => {
        const updateValidInput = () => {
          const hasNumberRegex = /\d/;
          if(advisorName.trim().length === 0) { // check if empty (bad input)
              setValidInput(false);
              setAdvisorNameErrMsg('Advisor name cannot be empty');
          }
          else if (hasNumberRegex.test(advisorName)) { //check if contains number (bad input)
              setValidInput(false);
              setAdvisorNameErrMsg('Advisor name cannot contain numbers');
          }
          else{ //good input
              setValidInput(true);
              setAdvisorNameErrMsg('');
          }
      }
      updateValidInput()      
    }, [advisorName]);

    return (
      <div>
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Add New Advisor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a new advisor, please enter their full name and click "Add Advisor".
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Full Name"
              fullWidth
              variant="standard"
              required
              onChange={event => setAdvisorName(event.target.value)}
              error={advisorNameErrMsg.length > 0}
              helperText={advisorNameErrMsg}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {onClose(false)}}>Close</Button>
            <Button onClick={() => {
              onClose(true, advisorName); 
            }} disabled={!validInput}>Add Advisor</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default AddAdvisorDialog;
