//From the home screen after logging in. Click "Create Advisor" button in the "Header" or on the "Home" page to go to this screen.
//Must have the role of Manager that is given through the enterprise application connected to this static web app.
//cross reference the AZURE_CLIENT_ID to find the enterprise application.

import * as React from 'react';
import Box from '@mui/material/Box';
import styles from '../../styles/styles';
import AddAdvisorDialog from './AddAdvisorDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

import {
  ADVISOR_CREATOR_ENDPOINT
} from "../../services/APIConstants";

const AdvisorCreator = () => {

  const cors = require('cors');
  const [openAddAdvisorDialog, setOpenAddAdvisorDialog] = React.useState(false);
  const [textFieldLabel, UpdateTextFieldLabel] = React.useState("");
  const [successSnackBarOpen, setSuccessSnackBarOpen] = React.useState(false);

  const createNewAdvisor = async (advisorName) => {
    //console.log(advisorName);
    const body = {
      "advisorName": advisorName.replace("'", "''")
    }
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': "Accept,authorization,Authorization, Content-Type"
      }
    };
    //const axios = require('axios');
    axios.post(ADVISOR_CREATOR_ENDPOINT, JSON.stringify(body), axiosConfig)
      .then(function (response) {
        UpdateTextFieldLabel(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleAdvisorDialogOpen = () => {
    setOpenAddAdvisorDialog(true);
  };

  const handleAdvisorDialogClose = (isSave, advisorName) => {
    if (isSave == true && advisorName) {
      createNewAdvisor(advisorName);
      handleSnackBarOpen();
    }
    setOpenAddAdvisorDialog(false);
  };

  //Snackbar and modal open/close handlers
  const handleSnackBarClose = () => {
    setSuccessSnackBarOpen(false);
  }

  const handleSnackBarOpen = () => {
    setSuccessSnackBarOpen(true);
  }

  React.useEffect(() => {
    handleAdvisorDialogOpen();
    UpdateTextFieldLabel("");
  }, [])

  return (
    <Box sx={styles["mui-advisor-creator-box"]}>
      <Box><AddAdvisorDialog open={openAddAdvisorDialog} onClose={handleAdvisorDialogClose}></AddAdvisorDialog></Box>
      <Box>
        {textFieldLabel}
      </Box>
      <Snackbar sx={{ marginTop: '-0.75%' }} autoHideDuration={9000} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'right' }} open={successSnackBarOpen} onClose={handleSnackBarClose}>
        <Alert variant="filled" severity="success" sx={{ fontSize: 16 }} onClose={handleSnackBarClose}>
          {textFieldLabel}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdvisorCreator;