
const primaryColor = "#002A4A";
const secondaryColor = "#00477d";
const tertiaryColor = "#e6f1fc";

const styles =  {
  //============= Root =============
  "root-theme": {    
    palette:{
      primary:{
        main: primaryColor
      },
      secondary:{
        main: secondaryColor
      }
    } 
  },
  "react-router-link": {  
    textDecoration: 'none', 
    color: 'inherit'
  },
  //============= Login =============
  "login-paper": {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: "24px",
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: tertiaryColor
  },
  "login-logo": {
    width: {
      xs: 90,
      sm: 120,
      md: 130,
      lg: 150,
      xl: 160
    },
    height: {
      xs: 90,
      sm: 120,
      md: 130,
      lg: 150,
      xl: 160
    },
    margin: 'auto'
  },
  "login-h1": {
    fontSize: {
      xs: '1rem',
      sm: '1.3rem',
      md: '2.1rem',
      lg: "2.7rem",
      xl: "3.5rem"
    }, 
    color: primaryColor, 
    margin: 'auto', 
    paddingBottom: {
      xs: 15,
      sm: 11,
      md: 13,
      lg: 15,
      xl: 17
    }, 
  },
  "login-button": {
    fontSize:  {
      xs: '0.8rem',
      sm: '1rem',
      md: '1.2rem',
      lg: '1.2rem',
      xl: '1.2rem'
    }, 
    width: '50%', 
    margin: 'auto', 
    marginBottom: {
      xs: 5,
      sm: 6,
      md: 8,
      lg: 15,
      xl: 17
    },
    backgroundColor: secondaryColor
  },
  //============= Home =============
  "mui-grid-home-1": {
    backgroundColor: secondaryColor,
    color: "white",
    padding: {
      xs: '3rem',
      sm: '3rem',
      md: '3rem',
      lg: '3.5rem',
      xl: '3.5rem'
    },
    fontSize: {
      xs: '0.6rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.125rem'
    },
    height: {
      xs: 150,
      sm: 150,
      md: 200,
      lg: 250,
      xl: 250
    },
    width: {
      xs: 75,
      sm: 150,
      md: 200,
      lg: 250,
      xl: 250
    },
    marginY: {
      xs: 2,
      sm: 3,
      md: 4,
      lg: 6,
      xl: 6
    },
    marginX: {
      xs: 2,
      sm: 3,
      md: 4,
      lg: 6,
      xl: 6
    },
  },
  "mui-box-home": {
    display:'flex', 
    width: '100%',
    marginX: '1.5vw' 

  },
  'mui-home-icon': {
    height: {
      xs: "1rem",
      sm: "1.7rem",
      md: "2rem",
      lg: "2.5rem",
      xl: "2.5rem"
    },
    width: {
      xs: "1rem",
      sm: "1.7rem",
      md: "2rem",
      lg: "2.5rem",
      xl: "2.5rem"
    },
  },

  //============= Header =============
  "mui-box-header-logo": {
    height:  {
      xs: 20,
      sm: 30,
      md: 35,
      lg: 40,
      xl: 40
    },
    width: {
      xs: 20,
      sm: 30,
      md: 35,
      lg: 40,
      xl: 40
    },
    padding: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 2,
      xl: 2
    },
    margin: 'auto'
  },
  "mui-box-header-icon": {
    height: {
      xs: "0.6rem",
      sm: "1rem",
      md: "1.2rem",
      lg: "1.5rem",
      xl: "1.5rem"
    },
    width: {
      xs: "0.6rem",
      sm: "1rem",
      md: "1.2rem",
      lg: "1.5rem",
      xl: "1.5rem"
    },
    paddingTop: {
      xs: 1,
      sm: 1.7,
      md: 1.9,
      lg: 2,
      xl: 2
    },
    paddingRight: {
      xs: 0.5,
      sm: 0.7,
      md: 1,
      lg: 1.25,
      xl: 1.25
    },
    marginLeft: {
      xs: 1,
      sm: 1.5,
      md: 2,
      lg: 2.5,
      xl: 2.5
    }
  },
  'mui-header-button':{
    height: {
      xs: '1rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2rem'
    },
    width: {
      xs: '1.5rem',
      sm: '1.5rem',
      md: '4rem',
      lg: '4.5rem',
      xl: '4.5rem'
    },
    fontSize: {
      xs: '0.4rem',
      sm: '0.6rem',
      md: '0.7rem',
      lg: '0.8rem',
      xl: '0.85rem'
    },
    color:'white',
  },
  "mui-box-header-container": { 
    display: "flex",
    width: "100%",
    marginY: 'auto'
  },
  "mui-header-divider": { 
    height: {
      xs: 15,
      sm: 20,
      md: 25,
      lg: 30,
      xl: 30
    },
    backgroundColor: 'white', 
    my: 'auto',
    ml: {
      xs: 1,
      sm: 1.5,
      md: 2,
      lg: 2.5,
      xl: 2.5
    }
  },
  "mui-box-header-h1": {
    fontSize: {
      xs: "0.7rem",
      sm: "1rem",
      md: "1.2rem",
      lg: "1.4rem",
      xl: "1.4rem"
    },
    margin: 'auto'
  },
  "mui-box-header-h3": {
    fontSize: {
      xs: '0.45rem',
      sm: '0.75rem',
      md: '0.9rem',
      lg: '1rem',
      xl: '1rem'
    },
    // marginTop: {
    //   xs: '1.97rem',
    //   sm: '2.3rem',
    //   md: '2.9rem',
    //   lg: '3rem',
    //   xl: '3.2rem'
    // },
  },
  "react-header-router-link": {  
    textDecoration: 'none', 
    color: 'inherit',
  },

  //============= UnassignedRepCodesTable =============
  "mui-rep-codes-table-container": {
    width: '95%',
    height: 700,
    flexGrow: 1, 
    display: 'flex',
    margin: 'auto',
    marginTop: '3%',
    backgroundColor: tertiaryColor,
  },
  'mui-rep-codes-table-header-cell': {
    fontSize: {
      xs: '0.5rem',
      sm: '0.6rem',
      md: '0.8rem',
      lg: '1rem',
      xl: '1rem'
    },
    color: "white",
    backgroundColor: secondaryColor
  },
  'mui-rep-codes-table-row-cell': {
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.2rem'
    }
  },
  'mui-rep-codes-table-button':{
    height: {
      xs: '1rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2rem'
    },
    width: {
      xs: '1.5rem',
      sm: '1.5rem',
      md: '4rem',
      lg: '4.5rem',
      xl: '4.5rem'
    },
    fontSize: {
      xs: '0.4rem',
      sm: '0.6rem',
      md: '0.7rem',
      lg: '0.8rem',
      xl: '0.85rem'
    }
  },
  'mui-rep-codes-table-circular-progress':{
    left: '48%', 
    top: '48%',
    transform: 'translate(-50%, -50%)'
  },
  'mui-rep-codes-table-header-row':{
    backgroundColor: secondaryColor
  },
  'mui-rep-codes-table-alert':{ 
    width: '100%', 
    fontSize: 16 
  },

  //============= SelectAdvisorsModal =============
  "mui-select-advisors-modal-box": {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: "24px",
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  "mui-select-advisors-table-container-1": {
    width: "100%",
    height: "100%",
    flexGrow: 1, 
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    backgroundColor: tertiaryColor,
  },
  "mui-select-advisors-table": {
    padding: 30,
    margin: 'auto',
    minWidth: 650,
  },
  "mui-select-advisors-table-container-2": {
    width: "100%",
    height: "100%",
    flexGrow: 1, 
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    backgroundColor: tertiaryColor,
    marginTop: 0.5
  },
  "mui-select-advisors-table-container-3": {
    height: "100%",
    maxHeight: 500,
    paddingLeft: 1,
  },
  'mui-select-advisors-modal-circular-progress':{
    position: 'absolute', 
    left: '45%', 
    top: '40%',
    transform: 'translate(-50%, -50%)'
  },
  'mui-select-advisors-modal-button': {
    height: {
      xs: '1.7rem',
      sm: '1.75rem',
      md: '2rem',
      lg: '3rem',
      xl: '3rem'
    },
    width: {
      xs: '4rem',
      sm: '5rem',
      md: '6rem',
      lg: '9rem',
      xl: '9rem'
    },
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.125rem'
    }
  },
    //============= SelectedAdvisors =============
  "mui-selected-advisors-table-container-1": {
    maxHeight: 315,
    minHeight: 150,
    width: "100%",
    flexGrow: 1, 
    display: 'flex',
    backgroundColor: tertiaryColor,
    marginTop: '1%'
  },
  "mui-selected-advisors-table-container-2": {
    width: "100%",
    maxHeight: {
      xs: 200,
      sm: 250,
      md: 300,
      lg: 300,
      xl: 300
    },
    minHeight: 150,
    flexGrow: 1, 
    display: 'flex',
    backgroundColor: tertiaryColor,
    marginTop: 0.5
  },
  'mui-selected-advisors-table-header-cell': {
    fontSize: {
      xs: '0.3rem',
      sm: '0.6rem',
      md: '0.8rem',
      lg: '1rem',
      xl: '1rem'
    }, 
    color: "white",
    backgroundColor: secondaryColor
  },
  'mui-selected-advisors-table-row-cell': { 
    fontSize: {
      xs: '0.3rem',
      sm: '0.65rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.2rem'
    },
  },
  'mui-selected-advisors-button':{
    width: {
      xs: 75,
      sm: 150,
      md: 190,
      lg: 250,
      xl: 250
    }, 
    height: {
      xs: 35,
      sm: 40,
      md: 50,
      lg: 50,
      xl: 50
    },
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.125rem'
    },
  },
  'mui-selected-advisors-table-header-cell-2': {
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '0.9rem',
      lg: '1rem',
      xl: '1.1rem'
    },     
    color: "white",
    backgroundColor: secondaryColor
  },
  'mui-selected-advisors-table-row-cell-2': { 
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.1rem'
    },    
  },
  'mui-selected-advisors-table-delete-cell': {
    width: 50 
  },
  'mui-selected-advisors-table-row': { 
    backgroundColor: secondaryColor
  },

  //============= SearchAdvisorsTable =============
  "mui-search-advisors-box": {
    width: '100%', 
    height: 500
  },
  "mui-search-advisors-table-container": {
    width: '100%',
    height: {
      xs: 300,
      sm: 300,
      md: 300,
      lg: 350,
      xl: 360
    },
    flexGrow: 1, 
    display: 'flex',
    margin: 'auto',
    backgroundColor: tertiaryColor,
  },
  "mui-search-advisors-table-row": {
    backgroundColor: secondaryColor
  },
  "mui-search-advisors-table-cell": {
    fontSize: {
      xs: '0.5rem',
      sm: '0.6rem',
      md: '0.75rem',
      lg: '0.75rem',
      xl: '1.1rem'
    },      
    color: "white"
  },
  "mui-search-advisors-table-cell-2": {
    fontSize: {
      xs: '0.5rem',
      sm: '0.6rem',
      md: '0.75rem',
      lg: '1rem',
      xl: '1.1rem'
    }    
  },

  'mui-search-advisors-rep-code-table-row-cell': { 
    fontSize: {
      xs: '0.2rem',
      sm: '0.4rem',
      md: '0.6rem',
      lg: '0.8rem',
      xl: '0.8rem'
    },
  },
  'mui-search-advisors-rep-code-table-header-cell': {
    fontSize: {
      xs: '0.2rem',
      sm: '0.4rem',
      md: '0.6rem',
      lg: '0.8rem',
      xl: '0.8rem'
    }, 
    color: "white",
    backgroundColor: secondaryColor
  },
  "mui-search-advisors-rep-code-table-container": {
    maxHeight: 200,
    minHeight: 100,
    width: "100%",
    flexGrow: 1, 
    display: 'flex',
    backgroundColor: tertiaryColor,
    marginBottom: '3%'
  },
  //============= AdvisorSearch =============
  "mui-advisor-search-table-container": {
    width: '95%',
    flexGrow: 1, 
    display: 'flex',
    margin: 'auto',
    backgroundColor: tertiaryColor,
  },
  //============= AdvisorCreator =============
  "mui-advisor-creator-box": {
    width: '100%', 
    height: 500,
    display: 'block',
    displayPrint: 'none',
    marginTop: '1%',
    marginLeft: '1%'
  },
  //============= UpdateRepCodesTable =============
  "mui-update-rep-codes-table-container": {
    width: '95%',
    flexGrow: 1,
    height: 650, 
    display: 'flex',
    margin: 'auto',
    marginTop: '1%',
    backgroundColor: tertiaryColor,
    
  },
  'mui-update-rep-codes-table-circular-progress':{
    left: '48%', 
    top: '55%',
    transform: 'translate(-50%, -50%)'
  },
  'mui-update-rep-codes-table-header-row':{
    backgroundColor: secondaryColor
  },
  'mui-update-rep-codes-table-row-cell': {
    fontSize: {
      xs: '0.5rem',
      sm: '0.75rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.2rem'
    }
  },
  'mui-update-rep-codes-table-alert':{ 
    width: '100%', 
    fontSize: '1rem' 
  },
  'mui-update-rep-codes-table-button':{
    height: {
      xs: '1rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2rem'
    },
    width: {
      xs: '1.5rem',
      sm: '1.5rem',
      md: '4rem',
      lg: '4.5rem',
      xl: '4.5rem'
    },
    fontSize: {
      xs: '0.4rem',
      sm: '0.6rem',
      md: '0.7rem',
      lg: '0.8rem',
      xl: '0.85rem'
    }
  },
  //============= SearchBar =============
  'mui-search-bar-box': {
    display: 'flex', 
    alignItems: 'center', 
    width: "70%", 
    margin: "auto"
  },
  'mui-search-bar-textfield':{
    flex: 1 
  },
  //============= TablePaginationActions =============
  'mui-search-pagination-table-box': { 
    flexShrink: 0, 
    ml: 2.5 
  },
  //============= TableToolBar =============
  'mui-table-tool-bar-toolbar': {
    pl: { sm: 2 },
    pr: { xs: 1, sm: 1 },
  },
  'mui-table-tool-bar-typography': { 
    flex: '1 1 100%' 
  }
}

export default styles;