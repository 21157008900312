import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import SearchAdvisorsTable from './SearchAdvisorsTable';
import SelectedAdvisors from './SelectedAdvisors';
import styles from '../../styles/styles';
import * as AdvisorService from '../../services/AdvisorService';
import CircularProgress from '@mui/material/CircularProgress';

const SelectAdvisorsModal = ({show, repCodeData, onClose, advisors, updateAdvisors}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedRepCode, setSelectedRepCode] = React.useState({});
  const [repCodesToDisplay, setRepCodesToDisplay] = React.useState([]);
  const [totalPercentAssigned, setTotalPercentAssigned] = React.useState(0);
  const [selectedAdvisors, setSelectedAdvisors] = React.useState([]);
  const [selectedAdvisorAmounts, setSelectedAdvisorAmounts] = React.useState({});
  const [selectingAdvisors, setSelectingAdvisors] = React.useState(false);
  const [searchAdvisorsSelected, setSearchAdvisorsSelected] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const UNASSIGNED_CODE = "UNAUNA";


  const updateTotalPercentAssigned = (percent) => {
    setTotalPercentAssigned(percent);
  }

  const findMatchingRecordForAdvisor = (advisorId) => {
    for(const record of repCodesToDisplay){
      if(advisorId === record.recommendedAdvisorId){
        return record;
      }
    }

    return null;
  }

  const saveAmountsForAdvisors = async () => {
    if(selectedRepCode.shCode === UNASSIGNED_CODE){
      for (const key of Object.keys(selectedAdvisorAmounts)){
        if(key === Object.keys(selectedAdvisorAmounts)[0]){ //overwrite the unassigned records
          await AdvisorService.assignRepCodesToAdvisorsUsingRepId(selectedRepCode.advisorDealerRefId, key, selectedAdvisorAmounts[key]/100); // convert from percent to decimal
        }
        else{ //create new records
          await AdvisorService.assignRepCodesToAdvisorsUsingDealerCode(selectedRepCode.dealerRep, key, selectedAdvisorAmounts[key]/100); // convert from percent to decimal
        }
      }
    }
    else{
      for (const advisorId of Object.keys(selectedAdvisorAmounts)){
        let repCode = findMatchingRecordForAdvisor(advisorId);
        if(repCode){
          await AdvisorService.assignRepCodesToAdvisorsUsingRepId(repCode.advisorDealerRefId, advisorId, selectedAdvisorAmounts[advisorId]/100); // convert from percent to decimal
        }
        else{
          await AdvisorService.assignRepCodesToAdvisorsUsingDealerCode(selectedRepCode.dealerRep, advisorId, selectedAdvisorAmounts[advisorId]/100); // convert from percent to decimal
        }
      }
    }
  }

  const handleSave = () => {
    saveAmountsForAdvisors().then(() => {
      handleModalClose(true);
    })
  }

  const handleModalClose = (isSave) => {
    setSelectedAdvisors([]);
    setSelectedAdvisorAmounts({});
    setTotalPercentAssigned(0);
    onClose(isSave);
    setOpen(false);
  };

  const handleSelectingOpen = () => {
    setSelectingAdvisors(true);
  }

  const handleSelectingClose = () => {
    setSearchAdvisorsSelected([]);
    setSelectingAdvisors(false);
  }

  const addSearchSelectedAdvisor = (advisorsIds) => {
    setSearchAdvisorsSelected(advisorsIds);
  }

  const handleAdvisorsAdd = () => {
    const newAdvisors = advisors.filter(advisor => (searchAdvisorsSelected.includes(advisor.advisorId)) && !selectedAdvisors.includes(advisor));
    setSelectedAdvisors(selectedAdvisors.concat(newAdvisors));
    handleSelectingClose()  
  }

  const removeSelectedAdvisor = (advisorToRemove) => {
    setSelectedAdvisors(selectedAdvisors.filter(advisor => advisor.advisorId !== advisorToRemove.advisorId));
  }

  const searchForPreselectedAdvisors = (repCodes) => {
    let preselectedAdvisors = []

    for(const repCode of repCodes){
      if(repCode.recommendedAdvisorId && repCode.recommendedAdvisorId > 0){
        let preselectedAdvisor = advisors.filter(advisor => advisor.advisorId === repCode.recommendedAdvisorId);
        preselectedAdvisors = preselectedAdvisors.concat(preselectedAdvisor);
      }
    }
    return preselectedAdvisors

  }

  const queryRepCodesWithDealerRep = (dealerRep) => {
    setIsLoading(true);
    AdvisorService.getRepCodesForShCode(dealerRep, true).then(repCodesArr => {
      
      setRepCodesToDisplay(repCodesArr);
      setSelectedAdvisors(selectedAdvisors.concat(searchForPreselectedAdvisors(repCodesArr)));

      setIsLoading(false);
    });
  };


  React.useEffect(() => {
    setOpen(show);
    if(show){
      setSelectedRepCode(repCodeData);

      if(repCodeData.shCode !== UNASSIGNED_CODE){
        queryRepCodesWithDealerRep(repCodeData.dealerRep);
      }
      else{
        setSelectedAdvisors(selectedAdvisors.concat(searchForPreselectedAdvisors([repCodeData])));
        setRepCodesToDisplay([repCodeData])
      }
    }
    // eslint-disable-next-line
  },[show, repCodeData]);

  return (
    <div>
      <Modal open={open}>
        <Box sx={styles['mui-select-advisors-modal-box']}>
          {isLoading && 
          <Box >
            <CircularProgress size={'10vh'} style={styles['mui-select-advisors-modal-circular-progress']} />
          </Box>
          }
          {!isLoading && selectingAdvisors && <SearchAdvisorsTable advisorsData={advisors} updateAdvisorsData={updateAdvisors} onSelect={addSearchSelectedAdvisor} repCodeData={repCodesToDisplay}/> }
          {!isLoading && !selectingAdvisors && <SelectedAdvisors totalPercentAssigned={totalPercentAssigned} updateTotalPercentAssigned={updateTotalPercentAssigned} 
          selectedAdvisors={selectedAdvisors} repCodeData={repCodesToDisplay} onAddAdvisor={handleSelectingOpen} onDelete={removeSelectedAdvisor} setSelectedAdvisorAmounts={setSelectedAdvisorAmounts} />}
          
          {!selectingAdvisors && <Box display='flex' alignItems="center" justifyContent="space-around">
            <Box sx={{marginRight: 'auto', marginTop: 1, marginLeft: 1}}   >
                <Button sx={styles['mui-select-advisors-modal-button']} variant='contained' onClick={() => handleModalClose(false)}>Close</Button>
              </Box>
              <Box sx={{marginLeft: 'auto', marginTop: 1, marginRight: 1}}>
                <Button sx={styles['mui-select-advisors-modal-button']} color="success" variant='contained' disabled={totalPercentAssigned !== 100} onClick={handleSave}>Save</Button>
              </Box>
            </Box>
          }
          {selectingAdvisors && <Box display='flex' alignItems="center" justifyContent="space-around">
            
              <Box sx={{marginRight: 'auto', marginTop: 1, marginLeft: 1}}>
                <Button id="close_button" sx={styles['mui-select-advisors-modal-button']} variant='contained' onClick={handleSelectingClose}>Back</Button>
              </Box>
              <Box sx={{marginLeft: 'auto', marginTop: 1, marginRight: 1}}>
                <Button id="add_button" sx={styles['mui-select-advisors-modal-button']} variant='contained' disabled={searchAdvisorsSelected.length === 0}  onClick={handleAdvisorsAdd}>Add</Button>
              </Box>
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
}

export default SelectAdvisorsModal;