import {
    GET_ADVISORS_ENDPOINT, 
    GET_REP_CODES_NO_ADVISOR_ENDPOINT, 
    SET_REP_CODE_FOR_ADVISOR_ENDPOINT,
    GET_REP_CODES_FOR_SH_CODE_ENDPOINT,
    CREATE_NEW_ADVISOR_ENDPOINT,
    ADVISOR_CREATOR_ENDPOINT,
    ADD_DEALER_REP_WITH_ADVISOR_ENDPOINT
} from "./APIConstants";

export const getAdvisors = async () => {
    const resp = await fetch( GET_ADVISORS_ENDPOINT , {
            "method": "GET",
            "headers": {"Content-Type": "application/json"}
        }
    );
    return await resp.json();
}

export const getUnassignedRepCodes = async () => {
    const resp = await fetch( GET_REP_CODES_NO_ADVISOR_ENDPOINT , {
            "method": "GET",
            "headers": {"Content-Type": "application/json"}
        }
    );

    return await resp.json();
}

export const getRepCodesForShCode = async (shCode, exact) => {
    const resp = await fetch( `${GET_REP_CODES_FOR_SH_CODE_ENDPOINT}?shCode=${shCode}&exact=${exact ? '1' : '0'}` , {
            "method": "GET",
            "headers": {"Content-Type": "application/json"}
        }
    );

    return await resp.json();
}

export const assignRepCodesToAdvisorsUsingRepId = async (repCodeId, advisorId, weight) => {
    const body = {
        "refId": repCodeId,
        "advisorId": advisorId,
        "weight": weight
    }
    const resp = await fetch( SET_REP_CODE_FOR_ADVISOR_ENDPOINT , {
            "method": "POST",
            "headers": {"Content-Type": "application/json"},
            "body": JSON.stringify(body)
        },

    );
}

export const assignRepCodesToAdvisorsUsingDealerCode = async (dealerCode, advisorId, weight) => {
    const body = {
        "dealerCode": dealerCode,
        "advisorId": advisorId,
        "weight": weight
    }
    console.log(body)
    const resp = await fetch( ADD_DEALER_REP_WITH_ADVISOR_ENDPOINT , {
            "method": "POST",
            "headers": {"Content-Type": "application/json"},
            "body": JSON.stringify(body)
        },

    );
}

export const createNewAdvisor = async (advisorName) => {
    const body = {
        "advisorName": advisorName
    }
    const resp = await fetch( CREATE_NEW_ADVISOR_ENDPOINT , {
            "method": "POST",
            "headers": {"Content-Type": "application/json"},
            "body": JSON.stringify(body)
        },

    );
    return;
}