import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import UnassignedRepCodesTable from './components/RepCodes/UnassignedRepCodesTable';
import UpdateRepCodesTable from './components/RepCodes/UpdateRepCodesTable';
import AdvisorSearch from './components/Advisors/AdvisorSearch';
import AdvisorCreator from './components/Advisors/AdvisorCreator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/system';
import styles from './styles/styles';
import React from 'react';
import Login from './components/Login';

const App = () => {
  const appTheme = createTheme(styles["root-theme"]);
  const [userInfo, setUserInfo] = React.useState();
  const [role, setRole] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setUserInfo(await getUserInfo());
    })();
  }, []);


  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      var result = false;
      if (clientPrincipal.claims?.length != 0) {
        var index = 0;
        while (!result && index < clientPrincipal.claims?.length) {
          if (clientPrincipal.claims[index].typ.localeCompare("roles") == 0) {
            setRole(clientPrincipal.claims[index].val);
            result = true;
          }
          index++;
        }
      }
      return clientPrincipal;
    } catch (error) {
      console.error('No profile could be found');
      return undefined;
    }
  }

  return (
    <Container disableGutters maxWidth="100%" sx={{ width: '100%' }}>
      <Router>
        <ThemeProvider theme={appTheme}>
          {userInfo && <Header />}
          <Routes>
            <Route exact path='/' element={userInfo && < Home />}></Route>
            <Route exact path='/login' element={userInfo ? (<Navigate replace to="/" />) : (< Login />)}></Route>
            <Route exact path='/UpdateRepCodes' element={(userInfo && userInfo.userRoles.includes("authenticated")) ? (< UpdateRepCodesTable />) : (<Navigate replace to="/" />)}></Route>
            <Route exact path='/UnassignedRepCodes' element={(userInfo && userInfo.userRoles.includes("authenticated")) ? (< UnassignedRepCodesTable />) : (<Navigate replace to="/" />)}></Route>
             <Route exact path='/AdvisorSearch' element={(userInfo && userInfo.userRoles.includes("authenticated")) ? (< AdvisorSearch />) : (<Navigate replace to="/" />)}></Route>
            <Route exact path='/AdvisorCreator' element={(userInfo && userInfo.userRoles.includes("authenticated")) ? (< AdvisorCreator />) : (<Navigate replace to="/" />)}></Route>
            {/* <Route exact path='/AdvisorSearch' element={(userInfo && role.localeCompare("authenticated")) ? (< AdvisorSearch />) : (<Navigate replace to="/" />)}></Route>
            <Route exact path='/AdvisorCreator' element={(userInfo && role.localeCompare("authenticated")) ? (< AdvisorCreator />) : (<Navigate replace to="/" />)}></Route> */}
          </Routes>
        </ThemeProvider>
      </Router>
    </Container>

  );
}

export default App;
