import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import styles from '../../styles/styles';
import PersonAddSharp from '@mui/icons-material/PersonAddSharp'
import Slider from '@mui/material/Slider';
import DeleteSharp from '@mui/icons-material/DeleteSharp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grow from '@mui/material/Grow';

const SelectedAdvisors = (props) => {

  const {totalPercentAssigned, updateTotalPercentAssigned, selectedAdvisors, repCodeData, onAddAdvisor, onDelete, setSelectedAdvisorAmounts} = props;
  const [repCodes, setRepCodes] = React.useState([]);
  const [assignedAmounts, setAssignedAmounts] = React.useState({});
  const [defaultAmount, setDefaultAmount] = React.useState(0);


  /********** START - FUNCTIONS **********/

  const updateTotalAssigned = () => {
    let amountAssigned = 0;
    for(const key of Object.keys(assignedAmounts)) {
        amountAssigned += assignedAmounts[key];
    }

    updateTotalPercentAssigned(amountAssigned);
  }

  const updateAdvisorAssignedAmount = (advisorId, amount) => {

    const newAssignedAmounts = assignedAmounts
    newAssignedAmounts[advisorId] = amount;
    setAssignedAmounts(newAssignedAmounts);
    setSelectedAdvisorAmounts(newAssignedAmounts);

    updateTotalAssigned();
  }

  const removeAdvisorAssignedAmount = (advisorId) => {
    const newAssignedAmounts = assignedAmounts
    delete newAssignedAmounts[advisorId];
    setAssignedAmounts(newAssignedAmounts);
    setSelectedAdvisorAmounts(newAssignedAmounts);
    
    updateTotalAssigned();
  }

  /********** END - FUNCTIONS **********/


  React.useEffect(() => {
    const numOfAdvisors = selectedAdvisors && selectedAdvisors.length > 0 ? selectedAdvisors.length : 1;
    const defaultAmountAssigned = Math.floor(100/numOfAdvisors);

    for(const advisor of selectedAdvisors) {
        updateAdvisorAssignedAmount(advisor.advisorId, defaultAmountAssigned);
    }
    setDefaultAmount(defaultAmountAssigned);
  } , [selectedAdvisors]);

  React.useEffect(() => {
  setRepCodes(repCodeData);

  },[repCodeData]);

  return (
    <div>
        <TableContainer component={Paper} sx={styles['mui-selected-advisors-table-container-1']}  align="center">
            <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow className="selected_rep_code_table_head_row">
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>Ref Id</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>AUM Weight</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>Dealer Rep</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>Dealer Code</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>Dealer Name</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell']}><b>Rep Name</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        repCodes.map((repCode, index) => (
                            <TableRow key={`rep_codes_selected_table_row_${index}`} className="selected_rep_code_table_row"> 
                            <TableCell className="selected_rep_code_table_row_ref_id" sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.advisorDealerRefId ? repCode.advisorDealerRefId : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.aumWeight ? repCode.aumWeight : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.dealerRep ? repCode.dealerRep : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.dealerCode ? repCode.dealerCode : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.dealerLongName ? repCode.dealerLongName : ''}
                            </TableCell>
                            <TableCell id={`selected_rep_advisor_name_${index}`} className="selected_rep_code_advisor_name" sx={styles['mui-selected-advisors-table-row-cell']} align="center">
                                {repCode.recommendedAdvisorName && repCode.recommendedAdvisorName.length > 0 ? repCode.recommendedAdvisorName : repCode.repName}
                            </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <Box display='flex' sx={{marginTop: 3}}>
            <Box sx={{marginRight: 'auto', marginTop: 1}}>
                <Typography sx={{fontSize: {xs: '0.5rem', sm: '0.6rem', md: '0.75rem', lg: '1rem', xl: '1rem', }}}variant="h6" component="div"><b>Total Percentage Assigned: {totalPercentAssigned}%</b></Typography> 
            </Box>
            <Box sx={{marginLeft: 'auto'}}>
                <Button id="add_advisor_button" sx={styles['mui-selected-advisors-button']} variant='contained' onClick={onAddAdvisor}><PersonAddSharp sx={{marginRight: 1}} />Add Advisor</Button>
            </Box>
        </Box>
        <Stack sx={{width: '100%', marginTop: 3}}>
            {totalPercentAssigned !== 100 && selectedAdvisors.length > 0 &&
                <Grow in={totalPercentAssigned !== 100 && selectedAdvisors.length > 0}>
                    <Alert variant="filled" severity="error">Cannot save until the total percent assigned is 100%.</Alert>
                </Grow>
            }
        </Stack>
        <TableContainer component={Paper} sx={styles['mui-selected-advisors-table-container-2']} align="center">
            <Table>
                <TableHead>
                    <TableRow sx={styles['mui-selected-advisors-table-row']}>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}><b>Advisor Name</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}><b>Advisor Type</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}><b>VPI Owner</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}><b>Current AUM</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}><b>Percentage to Assign</b></TableCell>
                        <TableCell align="center" sx={styles['mui-selected-advisors-table-header-cell-2']}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        
                {selectedAdvisors.map(advisor => {
                    return (
                        <TableRow key={`row_${advisor.advisorId}`}> 
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell-2']} align="center">
                            {advisor.advisorName}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell-2']} align="center">
                            {advisor.advisorType ? advisor.advisorType : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell-2']} align="center">
                            {advisor.vpiOwner ? advisor.vpiOwner : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell-2']} align="center">
                            {advisor.currentAUM ? advisor.currentAUM : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-row-cell-2']} align="center">
                            <Slider
                                key={`slider-${defaultAmount}`}
                                defaultValue={defaultAmount}
                                size="large"
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={(e, value) => updateAdvisorAssignedAmount(advisor.advisorId, value)}
                            />              
                            </TableCell>
                            <TableCell sx={styles['mui-selected-advisors-table-delete-cell']} align="center">
                            <Tooltip title="Delete">
                                <IconButton onClick={() => {
                                    removeAdvisorAssignedAmount(advisor.advisorId)
                                    updateTotalAssigned();
                                    onDelete(advisor)
                                }}>
                                <DeleteSharp />
                                </IconButton>
                            </Tooltip>
                            </TableCell>
                        </TableRow>
                    )
                })}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  );
}

export default SelectedAdvisors;