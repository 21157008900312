//This screen is the home screen after logging in.

import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styles from '../styles/styles';
import AssignmentSharp from '@mui/icons-material/AssignmentSharp';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

const Home = () => {

  const [managerDisplay, setManagerDisplay] = React.useState(false);

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      var result = false;
      if (clientPrincipal.claims?.length != 0) {
        var index = 0;
        while (!result && index < clientPrincipal.claims?.length) {
          if (clientPrincipal.claims[index].typ.localeCompare("roles") == 0 && clientPrincipal.claims[index].val.localeCompare("Manager") == 0) {
            result = true;
          }
          index++;
        }
      }
      setManagerDisplay(result);
    } catch (error) {
      console.error('No profile could be found');
    }
  }

  React.useEffect(() => {
    (async () => {
      await getUserInfo();
    })();
  }, []);

  return (
    <Box sx={styles["mui-box-home"]}>
      <Grid container justifyContent="left">
        <Grid item>
          <Link to="/UnassignedRepCodes" id="unassigned-rep-codes-grid-link" style={styles['react-router-link']}>
            <Grid container sx={styles["mui-grid-home-1"]} display="flex" justifyContent="center" >
              <Grid item align="center"><AssignmentSharp sx={styles['mui-home-icon']} /></Grid>
              <Grid item align="center"><b>Unassigned Dealer Rep Codes</b></Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/UpdateRepCodes" id="update-rep-codes-grid-link" style={styles['react-router-link']}>
            <Grid container sx={styles["mui-grid-home-1"]} display="flex" justifyContent="center" >
              <Grid item align="center"><EditIcon sx={styles['mui-home-icon']} /></Grid>
              <Grid item align="center"><b>Edit Rep Codes</b></Grid>
            </Grid>
          </Link>
        </Grid>
        {managerDisplay && <Grid item>
          <Link to="/AdvisorSearch" style={styles['react-router-link']}>
            <Grid container sx={styles["mui-grid-home-1"]} display="flex" justifyContent="center" >
              <Grid item align="center"><AssignmentSharp sx={styles['mui-home-icon']} /></Grid>
              <Grid item align="center"><b>Advisor Search</b></Grid>
            </Grid>
          </Link>
        </Grid>}
        {managerDisplay && <Grid item>
          <Link to="/AdvisorCreator" style={styles['react-router-link']}>
            <Grid container sx={styles["mui-grid-home-1"]} display="flex" justifyContent="center" >
              <Grid item align="center"><EditIcon sx={styles['mui-home-icon']} /></Grid>
              <Grid item align="center"><b>Create Advisor</b></Grid>
            </Grid>
          </Link>
        </Grid>}
      </Grid>
    </Box>
  )
}

export default Home