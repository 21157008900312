import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import styles from '../../styles/styles';

const TableToolbar = ({ numSelected, title, onClear }) => {
  
    return (
      <Toolbar
        sx={{
          ...styles['mui-table-tool-bar-toolbar'],
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={styles['mui-table-tool-bar-typography']}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={styles['mui-table-tool-bar-typography']}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        )}
  
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={onClear}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };

export default TableToolbar