//From the home screen after logging in. Click "Edit Rep Codes" button in the "Header" or on the "Home" page to go to this screen.

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import styles from '../../styles/styles';
import SelectAdvisorsModal from '../Advisors/SelectAdvisorsModal';
import * as AdvisorService from '../../services/AdvisorService';
import CircularProgress from '@mui/material/CircularProgress';
import TablePaginationActions from '../common/TablePaginationActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import SearchBar from '../common/SearchBar';


const UpdateRepCodesTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRepCode, setSelectedRepCode] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [repCodes, setRepCodes] = React.useState([]);
  const [advisors, setAdvisors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [successSnackBarOpen, setSuccessSnackBarOpen] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState('');
  
  /********** START - FUNCTIONS **********/

  //Queries
  const queryAdvisors = () => {
    AdvisorService.getAdvisors().then(advisorsArr => {
      setAdvisors(advisorsArr);
    })
  };
  const queryRepCodes = (shCode) => {
    setIsLoading(true);
    AdvisorService.getRepCodesForShCode(shCode, false).then(repCodesArr => {
      setRepCodes(repCodesArr);
    })
  };  

  //Snackbar and modal open/close handlers
  const handleSnackBarClose = () => {
    setSuccessSnackBarOpen(false);
  }

  const handleModalOpen = () => setOpenModal(true);

  const handleModalClose = (isSave) => {
    setOpenModal(false);

    if (isSave) {
      setSuccessSnackBarOpen(true);
      queryRepCodes(searchVal);

    }
  };

  //Table pagination handlers
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - repCodes.length);   // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //State handlers
  React.useEffect(() => {
    queryAdvisors();
  }, [])

  const onSearch = (event) => {
    let val = event.target.value.toUpperCase().trim();
    setSearchVal(val);

    if(val.length === 0) {
      setRepCodes([]);
    }
    else{
      queryRepCodes(val);
    }
  }

  React.useEffect(() => {
    setIsLoading(false);
  },[repCodes])
  /********** END - FUNCTIONS **********/  


  /********** START - CONSTANTS **********/  
  const tableHeaders = ["Ref Id", "AUM Weight", "Dealer Rep", "Dealer Name", "Rep Name", "Action"];

  /********** END - CONSTANTS **********/  
 
 
   return (
    <div>

    <Box sx={{marginTop: '2%'}}>
      <SearchBar searchBarId="search_rep_code_search_bar" label="Search Rep Codes" placeholder="Short Code or Dealer Rep" onSearch={onSearch}></SearchBar>
    </Box>
    <SelectAdvisorsModal show={openModal} repCodeData={selectedRepCode} onClose={handleModalClose} advisors={advisors} updateAdvisors={queryAdvisors}/>
    <TableContainer component={Paper} sx={styles['mui-update-rep-codes-table-container']}  align="center">
      <Table stickyHeader style={{ tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow sx={styles['mui-update-rep-codes-table-header-row']}>
            {tableHeaders.map((header, index) => (
              <TableCell key={`rep_table_header_${index}`} align="center" sx={styles['mui-rep-codes-table-header-cell']}><b>{header}</b></TableCell>
            ))}
          </TableRow>
        </TableHead>
        
        {isLoading && 
        <TableBody>
          <TableRow>
            <TableCell colSpan={tableHeaders.length} align="center">
              <CircularProgress size={'10vh'} style={styles['mui-update-rep-codes-table-circular-progress']} />
              </TableCell>
          </TableRow>
        </TableBody>
        }
        {!isLoading && 
          <TableBody>
            {(rowsPerPage > 0
              ? repCodes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : repCodes
            ).map((row, index) => (
              <TableRow key={`update_rep_table_row_${index}`} className="update_rep_code_table_row"> 
              <TableCell sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                  {row.advisorDealerRefId}
                </TableCell>
                <TableCell sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                  {row.aumWeight}
                </TableCell>
                <TableCell sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                  {row.dealerRep}
                </TableCell>
                <TableCell sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                  {row.dealerLongName}
                </TableCell>
                <TableCell id={`update_code_rep_name_${index}`} sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                  {row.recommendedAdvisorName.length > 0 ? row.recommendedAdvisorName : row.repName}
                </TableCell>
                <TableCell sx={styles['mui-update-rep-codes-table-row-cell']} align="center">
                <Button id={`update_code_button_${index}`} variant="contained" sx={styles['mui-update-rep-codes-table-button']} onClick={() => {
                  setSelectedRepCode(row);
                  handleModalOpen();
                }}>Update</Button></TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: `${6.5 * emptyRows}rem` }}>
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            )}
          </TableBody>
      }
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
              colSpan={6}
              count={repCodes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    <Snackbar open={successSnackBarOpen} autoHideDuration={6000} onClose={handleSnackBarClose}>
      <Alert variant="filled" severity="success" sx={styles['mui-update-rep-codes-table-alert']} onClose={handleSnackBarClose}>
        Successfully assigned rep code to advisor(s).
      </Alert>
    </Snackbar>
    </div>
  );
}

export default UpdateRepCodesTable;

