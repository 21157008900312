import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import styles from '../../styles/styles'


const SearchBar = ({label, placeholder, onSearch, searchBarId}) => {

    return (
    <Box textAlign="center" sx={styles['mui-search-bar-box']}>
        <TextField
            id={searchBarId}
            label={label} 
            color="secondary"
            sx={styles['mui-search-bar-textfield']}
            placeholder={placeholder}
            InputProps={{
                endAdornment: <IconButton><SearchIcon /></IconButton>
            }}
            onChange={onSearch}
        />            
    </Box>
    )
}

export default SearchBar