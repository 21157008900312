export const GET_ADVISORS_ENDPOINT = "/api/GetAdvisors";
export const SET_REP_CODE_FOR_ADVISOR_ENDPOINT = "/api/SetRepCodeForAdvisor";
export const GET_REP_CODES_NO_ADVISOR_ENDPOINT = "/api/GetRepCodesNoAdvisor";
export const GET_REP_CODES_FOR_SH_CODE_ENDPOINT = "/api/GetDealerRepsForAdvisorWithShCode";
export const CREATE_NEW_ADVISOR_ENDPOINT = "/api/CreateAdvisor";
export const ADVISOR_CREATOR_ENDPOINT = "/api/AdvisorCreator"
export const ADD_DEALER_REP_WITH_ADVISOR_ENDPOINT = "/api/AddDealerRepWithAdvisor";



