import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TableToolbar from '../common/TableToolBar';
import TablePaginationActions from '../common/TablePaginationActions';
import SearchBar from '../common/SearchBar';
import styles from '../../styles/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddAdvisorDialog from './AddAdvisorDialog';
import * as AdvisorService from '../../services/AdvisorService';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const SearchAdvisorsTable = ({advisorsData, updateAdvisorsData, onSelect, repCodeData}) => {

  const headers = ["", "Advisor Id", "Advisor Name", "Advisor Type", "VPI Owner", "Current AUM"];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [advisors, setAdvisors] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [openAddAdvisorDialog, setOpenAddAdvisorDialog] = React.useState(false);
  const [successSnackBarOpen, setSuccessSnackBarOpen] = React.useState(false);
  const [repCodes, setRepCodes] = React.useState([]);

  const actions = [
    { icon: <PersonAddIcon />, name: 'Add Advisor' },
  ];

  React.useEffect(() => {
    setAdvisors(advisorsData);
  }, [advisorsData])
  
  React.useEffect(() => {
    setRepCodes(repCodeData);
  },[repCodeData]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    onSelect(newSelected)
  };

  const onSearch = (event) => {
    if(event.target.value.length === 0) {
        setAdvisors(advisorsData);
    }
    else{
        setAdvisors(advisorsData.filter(item => item.advisorName.toLowerCase().includes(event.target.value.toLowerCase().trim())));
    }
  }

  const createNewAdvisor = async (advisorName) => {
    await AdvisorService.createNewAdvisor(advisorName.trim());
  }

  const handleAdvisorDialogOpen = () => {
    setOpenAddAdvisorDialog(true);
  };

  const handleAdvisorDialogClose = (isSave, advisorName) => {
    if(isSave == true && advisorName) {
      createNewAdvisor(advisorName).then(() => {
        updateAdvisorsData();
        handleSnackBarOpen();
      })
    }
    setOpenAddAdvisorDialog(false);
  };

  const clearSelected = () => {
    setSelected([]);
    onSelect([]);
  }

  const isSelected = (advisorId) => selected.indexOf(advisorId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - advisors.length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Snackbar and modal open/close handlers
  const handleSnackBarClose = () => {
    setSuccessSnackBarOpen(false);
  }

  const handleSnackBarOpen = () => {
    setSuccessSnackBarOpen(true);
  }

  return (
    <Box sx={styles["mui-search-advisors-box"]}>
      <AddAdvisorDialog open={openAddAdvisorDialog} onClose={handleAdvisorDialogClose}></AddAdvisorDialog>
      <TableContainer component={Paper} sx={styles["mui-search-advisors-rep-code-table-container"]}  align="center">
            <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>Ref Id</b></TableCell>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>AUM Weight</b></TableCell>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>Dealer Rep</b></TableCell>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>Dealer Code</b></TableCell>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>Dealer Name</b></TableCell>
                        <TableCell align="center" sx={styles['mui-search-advisors-rep-code-table-header-cell']}><b>Rep Name</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        repCodes.map((repCode, index) => (
                            <TableRow key={`rep_codes_selected_table_row_${index}`}> 
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.advisorDealerRefId ? repCode.advisorDealerRefId : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.aumWeight ? repCode.aumWeight : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.dealerRep ? repCode.dealerRep : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.dealerCode ? repCode.dealerCode : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.dealerLongName ? repCode.dealerLongName : ''}
                            </TableCell>
                            <TableCell sx={styles['mui-search-advisors-rep-code-table-row-cell']} align="center">
                                {repCode.recommendedAdvisorName && repCode.recommendedAdvisorName.length > 0 ? repCode.recommendedAdvisorName : repCode.repName}
                            </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
      <Box sx={{marginTop: 1, marginBottom: 1,  display: 'flex'}}>
          <SearchBar searchBarId="add_advisor_search_bar" label="Search Advisors" placeholder="Advisor Name" onSearch={onSearch}></SearchBar>

          <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>

                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 1, right: 4 }}
                    icon={<SpeedDialIcon />}
                    direction="left"
                >
                    {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={handleAdvisorDialogOpen}
                    />
                    ))}
                </SpeedDial>
            </Box>
      </Box>
    <Paper sx={{ width: '100%', mt: 3 }}>
      <TableToolbar numSelected={selected.length} title="Advisors" onClear={clearSelected}/>
      <TableContainer component={Paper}  align="center" sx={styles['mui-search-advisors-table-container']}>
        <Table style={{ tableLayout: 'fixed' }} aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={styles["mui-search-advisors-table-row"]}>
                {headers.map((header) => (
                  <TableCell align="center" key={`search_tableheader_${header}`}
                  sx={styles["mui-search-advisors-table-cell"]}><b>{header}</b></TableCell>
                ))
                }
            </TableRow>
          </TableHead>
              <TableBody>
                  {(rowsPerPage > 0
                  ? advisors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : advisors
                  ).map((advisor, index) => {
                    const isItemSelected = isSelected(advisor.advisorId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow 
                        hover
                        onClick={(event) => {
                          handleClick(event, advisor.advisorId)
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`search_tablerow_${advisor.advisorId}`}
                        selected={isItemSelected}
                      >   
                        <TableCell padding="checkbox" align="center">
                          <Checkbox
                            id={labelId}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell id={`search_advisor_id_${index}`} sx={styles["mui-search-advisors-table-cell-2"]} align="center" scope="row" component="th">
                          {advisor.advisorId}
                        </TableCell>
                        <TableCell id={`search_advisor_name_${index}`} sx={styles["mui-search-advisors-table-cell-2"]} align="center" scope="row" component="th">
                          {advisor.advisorName}
                        </TableCell>

                        <TableCell sx={styles["mui-search-advisors-table-cell-2"]} align="center" scope="row" component="th">
                          {advisor.advisorType}
                        </TableCell>
                        <TableCell sx={styles["mui-search-advisors-table-cell-2"]} align="center" scope="row" component="th">
                          {advisor.vpiOwner}
                        </TableCell>
                        <TableCell sx={styles["mui-search-advisors-table-cell-2"]} align="center" scope="row" component="th">
                          {advisor.currentAUM}
                        </TableCell>
                      </TableRow>
                    )
                  })
                  }

                  {emptyRows > 0 && (
                  <TableRow style={{ height: `${1.5 * emptyRows}rem` }}>
                      <TableCell colSpan={6} />
                  </TableRow>
                  )}
              </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5]}
                colSpan={headers.length}
                count={advisors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
    <Snackbar sx={{marginBottom: 1}} autoHideDuration={6000} anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'center' }} open={successSnackBarOpen} onClose={handleSnackBarClose}>
          <Alert variant="filled" severity="success" sx={{fontSize: 16 }} onClose={handleSnackBarClose}>
            Successfully created new advisor.
          </Alert>
    </Snackbar>
    </Box>
  );
}

export default SearchAdvisorsTable;