//This is the header of the home screen after logging in. 

import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import styles from '../styles/styles';
import AssignmentSharp from '@mui/icons-material/AssignmentSharp';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

const Header = () => {

  const redirect = '/login';
  const [managerDisplay, setManagerDisplay] = React.useState(false);

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      var result = false;
      if (clientPrincipal.claims?.length != 0) {
        var index = 0;
        while (!result && index < clientPrincipal.claims?.length) {
          if (clientPrincipal.claims[index].typ.localeCompare("roles") == 0 && clientPrincipal.claims[index].val.localeCompare("Manager") == 0) {
            result = true;
          }
          index++;
        }
      }
      setManagerDisplay(result);
    } catch (error) {
      console.error('No profile could be found');
    }
  }

  React.useEffect(() => {
    (async () => {
      await getUserInfo();
    })();
  }, []);

  return (
    <div>
      {<AppBar position="static">
        <Box sx={styles['mui-box-header-container']}>
          <Link to="/" id="home-header-link" style={styles['react-router-link']}>
            <Box sx={styles['mui-box-header-container']}>
              <Box
                component="img"
                sx={styles['mui-box-header-logo']}
                alt="VPI Logo"
                src="vpi-logo.svg"
              />
              <Box component="h1" sx={styles["mui-box-header-h1"]}>
                Advisor Management
              </Box>
            </Box>
          </Link>

          <Divider sx={styles['mui-header-divider']} orientation="vertical" />
          <Box display={'flex'} sx={{ marginY: 'auto' }}>
            <Link to="/UnassignedRepCodes" id="unassigned-rep-codes-header-link" style={styles['react-header-router-link']}>
              <Box sx={styles['mui-box-header-container']}>
                <AssignmentSharp sx={styles['mui-box-header-icon']} />
                <Box component="h3" sx={styles["mui-box-header-h3"]}>
                  Unassigned Dealer Rep Codes
                </Box>
              </Box>
            </Link>
            <Link to="/UpdateRepCodes" id="update-rep-codes-header-link" style={styles['react-header-router-link']}>
              <Box sx={styles['mui-box-header-container']}>
                <EditIcon sx={styles['mui-box-header-icon']} />
                <Box component="h3" sx={styles["mui-box-header-h3"]}>
                  Edit Rep Codes
                </Box>
              </Box>
            </Link>
            {managerDisplay && <Link to="/AdvisorSearch" style={styles['react-header-router-link']}>
              <Box sx={styles['mui-box-header-container']}>
                <AssignmentSharp sx={styles['mui-box-header-icon']} />
                <Box component="h3" sx={styles["mui-box-header-h3"]}>
                  Advisor Search
                </Box>
              </Box>
            </Link>}
            {managerDisplay && <Link to="/AdvisorCreator" style={styles['react-header-router-link']}>
              <Box sx={styles['mui-box-header-container']}>
                <EditIcon sx={styles['mui-box-header-icon']} />
                <Box component="h3" sx={styles["mui-box-header-h3"]}>
                  Create Advisor
                </Box>
              </Box>
            </Link>}
          </Box>
          <Box sx={{ marginLeft: 'auto', marginY: 'auto', marginRight: '1%' }}>
            <Button variant="text" sx={styles['mui-header-button']} href={`/.auth/logout?post_logout_redirect_uri=${redirect}`}>Logout</Button>
          </Box>
        </Box>
      </AppBar>}
    </div>
  )
}

export default Header