//From the home screen after logging in. Click "Advisor Search" button in the "Header" or on the "Home" page to go to this screen.
//Must have the role of Manager that is given through the enterprise application connected to this static web app.
//cross reference the AZURE_CLIENT_ID to find the enterprise application.

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePaginationActions from '../common/TablePaginationActions';
import SearchBar from '../common/SearchBar';
import styles from '../../styles/styles';
import * as AdvisorService from '../../services/AdvisorService';

const AdvisorSearch = ({ advisorName, onSelect }) => {

  const headers = ["Advisor Id", "Advisor Name", "Advisor Type", "VPI Owner", "Current AUM"];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [advisors, setAdvisors] = React.useState([]);
  const [allAdvisors, setAllAdvisors] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [createdAdvisorName, setCreatedAdvisorName] = React.useState("");

  const queryAdvisors = () => {
    AdvisorService.getAdvisors().then(advisorsArr => {
      setAllAdvisors(advisorsArr);
    })
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    onSelect(newSelected)
  };

  const onSearch = (event) => {
    if (event.target.value.length === 0 && allAdvisors != null) {
      setAdvisors(allAdvisors);
    }
    else if (allAdvisors != null) {
      setAdvisors(allAdvisors.filter(item => item.advisorName.toLowerCase().includes(event.target.value.toLowerCase().trim())));
    }
  }

  const isSelected = (advisorId) => selected.indexOf(advisorId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - advisors.length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    queryAdvisors();
    setCreatedAdvisorName((advisorName != null) ? advisorName : "");
  }, [])

  return (
    <Box>
      <Box sx={{ marginTop: '2%' }}>
        <SearchBar searchBarId="add_advisor_search_bar" label="Search Advisors" placeholder="Advisor Name" onSearch={onSearch} value={createdAdvisorName}></SearchBar>
      </Box>
      <Paper sx={{ width: '100%', mt: 3 }}>
        <TableContainer component={Paper} align="center" sx={styles['mui-advisor-search-table-container']}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow sx={styles["mui-search-advisors-table-row"]}>
                {headers.map((header) => (
                  <TableCell align="center" key={`search_tableheader_${header}`}
                    sx={styles["mui-search-advisors-table-cell"]}><b>{header}</b></TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? advisors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : advisors
              ).map((advisor, index) => {
                const isItemSelected = isSelected(advisor.advisorId);

                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      handleClick(event, advisor.advisorId)
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`search_tablerow_${advisor.advisorId}`}
                    selected={isItemSelected}
                  >
                    <TableCell id={`search_advisor_id_${index}`} sx={styles["mui-advisor-search-table-cell-2"]} align="center" scope="row" component="th">
                      {advisor.advisorId}
                    </TableCell>
                    <TableCell id={`search_advisor_name_${index}`} sx={styles["mui-advisor-search-table-cell-2"]} align="center" scope="row" component="th">
                      {advisor.advisorName}
                    </TableCell>

                    <TableCell sx={styles["mui-advisor-search-table-cell-2"]} align="center" scope="row" component="th">
                      {advisor.advisorType}
                    </TableCell>
                    <TableCell sx={styles["mui-advisor-search-table-cell-2"]} align="center" scope="row" component="th">
                      {advisor.vpiOwner}
                    </TableCell>
                    <TableCell sx={styles["mui-advisor-search-table-cell-2"]} align="center" scope="row" component="th">
                      {advisor.currentAUM}
                    </TableCell>
                  </TableRow>
                )
              })
              }

              {emptyRows > 0 && (
                <TableRow style={{ height: `${6.5 * emptyRows}rem` }}>
                  <TableCell colSpan={headers.length} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={headers.length}
                  count={advisors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default AdvisorSearch;