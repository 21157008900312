//This screen is the login page.

import React from 'react';
import Paper from '@mui/material/Paper';
import styles from '../styles/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const Login = () => {
  const redirect = '/';

  React.useEffect(() => {
    window.location.href= `/.auth/login/aad?post_login_redirect_uri=${redirect}`;
  }, []);

  return (
    <Paper sx={styles["login-paper"]} elevation={3}>
        <Box component="img" sx={styles["login-logo"]} alt="VPI Logo" src="vpi-logo-2.png"/>
        <Box component="h1" sx={styles["login-h1"]}>
                Advisor Management Portal
        </Box>
        <Button variant="contained" size="large" sx={styles["login-button"]} href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`}>
            Login
        </Button>            
    </Paper>
  )
}

export default Login